.panel {
  display: grid;
  grid-template-rows: 1fr;
  opacity: 1;
  transition: opacity 0.5s ease-in-out;
}

.panel[hidden] {
  grid-template-rows: 0fr;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

@media (prefers-reduced-motion: no-preference) {
  .panel {
    transition: grid-template-rows 0.5s ease-in-out, opacity 0.5s ease-in-out;
  }
  
  .panel[hidden] {
    transition: grid-template-rows 0.3s ease-in-out, opacity 0.3s ease-in-out;
  }
}
