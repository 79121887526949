.signalAnimation {
  animation: signal 3s ease-in-out infinite alternate;
}

/* set up the signaling animation */
@keyframes signal {
  from {
    transform: translateY(-32px);
    opacity: 1;
  }

  to {
    transform: translateY(0);
    opacity: 0.3;
  }
}
